import * as React from "react";
import { css } from "@emotion/react";
import {
  fontSize,
  fontWeight,
  letterSpacing,
} from "main/javascripts/styles/base/typographyStyle";
import {
  accentColor,
  shadeColorLogHex,
} from "main/javascripts/styles/base/colorStyle";
import { space } from "main/javascripts/styles/base/spaceStyle";
import { borderRadius } from "main/javascripts/styles/base/borderStyle";
import { minResolution } from "main/javascripts/styles/base/responsiveStyle";
import { moreThanBreakpoint } from "../../styles/base/responsiveStyle";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  styleKey?: keyof IStyle;
}

// 旧コンポーネントにあったラッパーdivを削除しているので注意
// 余白は上位コンポーネントで設定する
const ButtonComponent: React.FC<IProps> = (
  props: IProps
): React.ReactElement<IProps> => {
  const { children, styleKey = "button", ...rest } = props;
  return (
    <button css={buttonStyle[styleKey]} {...rest}>
      {children}
    </button>
  );
};
export const Button: any = React.memo(ButtonComponent);

export interface IStyle {
  button: any;
  buttonSmall: any;
  buttonSecondary: any;
  buttonSmallSecondary: any;
  buttonAdd: any;
  buttonRemove: any;
  buttonAlert: any;
  buttonSmallAlert: any;
  buttonAlertSecondary: any;
  buttonSmallAlertSecondary: any;
  buttonSubmit: any;
}

const buttonBaseStyle = css`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: ${letterSpacing.text};
  border-radius: ${borderRadius.normal};
  margin: 0;
  width: 100%;
  transition: background 0.4s ease, opacity 0.4s ease;
`;
const buttonDefaultBaseStyle = css`
  ${buttonBaseStyle};
  font-weight: ${fontWeight.regular};
  color: white;
  background-color: ${accentColor.primaryColor};
  padding: 0 ${space.atom};
  border: none;
  min-width: 64px;
  &:hover {
    background-color: ${accentColor.primaryDarkColor};
  }
  &:disabled {
    opacity: 0.75;
    cursor: default;
    &:hover {
      background-color: ${accentColor.primaryColor};
    }
  }
  ${minResolution("scale2x")} {
    font-weight: ${fontWeight.medium};
  }
`;
const buttonDefaultSecondaryBaseStyle = css`
  ${buttonBaseStyle};
  font-weight: ${fontWeight.regular};
  color: ${accentColor.primaryColor};
  background-color: #fff;
  padding: 0 ${space.atom};
  border: 1px solid ${accentColor.primaryColor};
  min-width: 64px;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.3;
    cursor: default;
    &:hover {
      background-color: #fff;
    }
  }
`;
const buttonAlertBaseStyle = css`
  ${buttonBaseStyle};
  font-weight: ${fontWeight.regular};
  color: white;
  background-color: ${accentColor.alertColor};
  padding: 0 ${space.atom};
  border: none;
  min-width: 64px;
  &:hover {
    background-color: ${shadeColorLogHex(accentColor.alertColor, 1, 0.1)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background-color: ${accentColor.alertColor};
    }
  }
  ${minResolution("scale2x")} {
    font-weight: ${fontWeight.medium};
  }
`;
const buttonAlertSecondaryBaseStyle = css`
  ${buttonBaseStyle};
  font-weight: ${fontWeight.regular};
  color: ${accentColor.alertColor};
  background-color: #fff;
  padding: 0 ${space.atom};
  border: 1px solid ${accentColor.alertColor};
  min-width: 64px;
`;

export const buttonStyle: IStyle = {
  button: css`
    ${buttonDefaultBaseStyle};
    font-size: ${fontSize.mediumHeading};
    height: 40px;
    line-height: 40px;
  `,
  buttonSmall: css`
    ${buttonDefaultBaseStyle};
    font-size: ${fontSize.subHeading};
    height: 40px;
    line-height: 40px;
    ${moreThanBreakpoint("tablet")} {
      height: 36px;
      line-height: 36px;
    }
  `,
  buttonSecondary: css`
    ${buttonDefaultSecondaryBaseStyle};
    font-size: ${fontSize.mediumHeading};
    height: 40px;
    line-height: 38px;
  `,
  buttonSmallSecondary: css`
    ${buttonDefaultSecondaryBaseStyle};
    font-size: ${fontSize.subHeading};
    height: 40px;
    line-height: 38px;
    ${moreThanBreakpoint("tablet")} {
      height: 36px;
      line-height: 34px;
    }
  `,
  buttonAdd: css`
    ${buttonDefaultSecondaryBaseStyle};
    font-size: ${fontSize.body};
    height: 32px;
    line-height: 30px;
    background: none;
  `,
  buttonRemove: css`
    ${buttonDefaultSecondaryBaseStyle};
    color: ${accentColor.secondaryColor};
    border-color: ${accentColor.secondaryColor};
    font-size: ${fontSize.body};
    height: 32px;
    line-height: 30px;
    background: none;
  `,
  buttonAlert: css`
    ${buttonAlertBaseStyle};
    font-size: ${fontSize.mediumHeading};
    height: 40px;
    line-height: 38px;
  `,
  buttonSmallAlert: css`
    ${buttonAlertBaseStyle};
    font-size: ${fontSize.subHeading};
    height: 40px;
    line-height: 38px;
    ${moreThanBreakpoint("tablet")} {
      height: 36px;
      line-height: 34px;
    }
  `,
  buttonAlertSecondary: css`
    ${buttonAlertSecondaryBaseStyle};
    font-size: ${fontSize.mediumHeading};
    height: 40px;
    line-height: 38px;
  `,
  buttonSmallAlertSecondary: css`
    ${buttonAlertSecondaryBaseStyle};
    font-size: ${fontSize.subHeading};
    height: 40px;
    line-height: 38px;
    ${moreThanBreakpoint("tablet")} {
      height: 36px;
      line-height: 34px;
    }
  `,
  buttonSubmit: css`
    position: relative;
    font-size: ${fontSize.mediumHeading};
    font-weight: ${fontWeight.regular};
    color: white;
    background-color: ${accentColor.primaryColor};
    padding: 0 ${space.atom};
    border: none;
    border-radius: ${borderRadius.normal};
    margin: 0;
    width: 100%;
    min-width: 64px;
    height: 54px;
    line-height: 54px;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    letter-spacing: ${letterSpacing.text};
    transition: background 0.4s ease, opacity 0.4s ease;
    &:before {
      content: "";
      border: 1px solid #fff;
      display: block;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% - ${space.atom});
      height: calc(100% - ${space.atom});
      transform: translate(-50%, -50%);
      box-sizing: border-box;
    }
    &:hover {
      background-color: ${accentColor.primaryDarkColor};
    }
    &:disabled {
      opacity: 0.5;
    }
    ${minResolution("scale2x")} {
      font-weight: ${fontWeight.medium};
    }
  `,
};

// const blockStyle = css`
//   padding: ${space.atom};
// `;
